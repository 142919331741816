import { useDefinedContext } from "hooks/use-context-exist";
import { PartnersProgramGeneralInformationContext } from "views";
import { Capabilities } from "./capabilities";
import { CompanyNews } from "./company-news";
import { Features } from "./features";
import { FirstScreen } from "./first-screen";
import { WelcomePanel } from "./first-screen/welcome-panel";
import { BecomeAnAffilate } from "./become-an-affilate";
import { OurPartners } from "./our-partners";
import { Partner } from "./partner";
import { BecomeAnAffiliateTitle } from "./become-an-affilate/become-an-affilate-title";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import {
    _1XBET_PARTNERS,
    MELBET_AFFILIATES,
    MELBET_PARTNERS,
    OPPABET_PARTNERS,
    COINPLAY_PARTNERS,
    AFROPARI_PARTNERS,
    IBETIN_AFFILATES,
    FUNPARI_PARTNERS,
    RIZO_BET,
    PARTNERS_4CLOVER,
    PARTNERS_WEPARI,
    PLANBET_PARTNERS,
    DERBYBET_PARTNERS,
    BETPAY_PARTNERS,
} from "../../../../server/partner-programs";
import { Title } from "styled/layouts";
import { TestimonialsSlider } from "components/components-common/slider";
import { MainInformation } from "./coinplay-main-information";

interface IInjectedProps {}

interface IProps extends IInjectedProps {}

export const Home = React.memo((props: IProps) => {
    const { landingMainPage, stylesTheme, partnersProgramId, visibility } =
        useDefinedContext(PartnersProgramGeneralInformationContext);
    const [__] = useTranslation();

    // этот раздел относиться только к регулярному лендингу,
    // без этой инфы нет смысла продолжать
    if (!landingMainPage) {
        return null;
    }

    let [firstPartTitle, secondPartTitle] = __(
        "География {separator}наших партнеров",
    ).split("{separator}");
    let colorPartner = stylesTheme.accentColor;
    let annotation = __(
        "Мы сотрудничаем на самых выгодных условиях и осуществляем максимально широкий охват аудитории",
    );
    if (partnersProgramId === OPPABET_PARTNERS) {
        [firstPartTitle, secondPartTitle] = ["World ", "Map"];
        colorPartner = stylesTheme.textColor;
    }
    if (
        partnersProgramId === AFROPARI_PARTNERS ||
        partnersProgramId === FUNPARI_PARTNERS
    ) {
        [firstPartTitle, secondPartTitle] = __(
            "География {separator}нашего бренда",
        ).split("{separator}");
    }
    if (partnersProgramId === IBETIN_AFFILATES) {
        [firstPartTitle, secondPartTitle] = [__("Индия"), ""];
        annotation = __(
            "На данный момент мы работаем только с клиентами из Индии и партнерами со всего мира",
        );
    }

    if (partnersProgramId === PLANBET_PARTNERS) {
        [firstPartTitle, secondPartTitle] = [
            __("Достигайте"),
            __(" больших доходов"),
        ];
        annotation = __(
            "Зарабатывайте в одной из самых быстрорастущих партнерских программ в мире!",
        );
    }

    if (partnersProgramId === PARTNERS_WEPARI) {
        colorPartner = stylesTheme.textColor;
    }

    if (partnersProgramId === BETPAY_PARTNERS) {
        [firstPartTitle, secondPartTitle] = [
            __("Более 49 999 партнеров"),
            __(" по всему миру."),
        ];
        annotation = __(
            "Мы предлагаем наилучшие условия сотрудничества, максимально расширяя охват вашей аудитории.",
        );
    }

    return (
        <>
            <FirstScreen>
                <WelcomePanel
                    textColor={stylesTheme.landingTextColor}
                    bgColor={stylesTheme.textColor}
                />
            </FirstScreen>
            <Features termsIcons={landingMainPage.termsIcons} />
            <Capabilities
                capabilities={landingMainPage.capabilities}
                textColor={stylesTheme.mutedTextColor}
            />
            {visibility.becomeAnAffiliate && (
                <BecomeAnAffilate
                    title={
                        <BecomeAnAffiliateTitle
                            firstPartTitle={firstPartTitle}
                            secondPartTitle={secondPartTitle}
                            annotation={annotation}
                        />
                    }
                    picture={<img src={"/config-files/map"} alt="" />}
                    textColor={stylesTheme.mutedTextColor}
                />
            )}
            {partnersProgramId !== MELBET_PARTNERS &&
                partnersProgramId !== MELBET_AFFILIATES &&
                partnersProgramId !== RIZO_BET &&
                partnersProgramId !== PLANBET_PARTNERS &&
                partnersProgramId !== DERBYBET_PARTNERS &&
                partnersProgramId !== PARTNERS_4CLOVER && (
                    <Partner
                        importantPartnerTitle={
                            landingMainPage.importantPartnerTitle
                        }
                        currencyName={landingMainPage.currencyName}
                        colorPartner={colorPartner}
                    />
                )}
            {visibility.showingNews.showNews &&
            partnersProgramId !== _1XBET_PARTNERS ? (
                <CompanyNews />
            ) : null}
            {partnersProgramId === COINPLAY_PARTNERS && <MainInformation />}
            {landingMainPage.partnersLogos.length !== 0 && (
                <OurPartners partnersLogos={landingMainPage.partnersLogos} />
            )}
            <TestimonialsSlider
                textColor={darken(0.1, stylesTheme.landingTextColor)}
                componentTitle={<Title>{__("Отзывы")}</Title>}
                activeColor={stylesTheme.accentColor}
                numberOfSlides={1}
            />
        </>
    );
}) as React.ComponentType<Subtract<IProps, IInjectedProps>>;
